import { CONTEXT } from "@constants";
import notify from '@states/global/notify'

export const defaultData = {
	auth: {
		isAuthenticated: false,
		username: null,
		token: null,
		uuid: null,
		email: null,
		role: null,
		unique_code: null
	},
	catalogos: {
		categories: []
	},
	zipCodes: [],
	notify,
	blogs: [],
	requestLoading: false,
	requestText: null,
	route: '/',
	setRoute: () => {},
	setRequestLoading: () => {},
	setRequestText: () => {},
	setAuth: () => {},
	setZipCodes: () => {},
	setCatalogos: () => {},
	setBlogs: () => {},
	setNotify: () => {},
};

const defaultState =
	typeof window !== "undefined"
		? JSON.parse(sessionStorage.getItem(CONTEXT)) || defaultData
		: defaultData;

export default defaultState;
