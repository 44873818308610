// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-blogs-agregar-jsx": () => import("./../../../src/pages/admin/blogs/agregar.jsx" /* webpackChunkName: "component---src-pages-admin-blogs-agregar-jsx" */),
  "component---src-pages-admin-blogs-blog-id-jsx": () => import("./../../../src/pages/admin/blogs/[blogId].jsx" /* webpackChunkName: "component---src-pages-admin-blogs-blog-id-jsx" */),
  "component---src-pages-admin-blogs-index-jsx": () => import("./../../../src/pages/admin/blogs/index.jsx" /* webpackChunkName: "component---src-pages-admin-blogs-index-jsx" */),
  "component---src-pages-admin-clientes-[cliente]-jsx": () => import("./../../../src/pages/admin/clientes/[cliente].jsx" /* webpackChunkName: "component---src-pages-admin-clientes-[cliente]-jsx" */),
  "component---src-pages-admin-clientes-agregar-jsx": () => import("./../../../src/pages/admin/clientes/agregar.jsx" /* webpackChunkName: "component---src-pages-admin-clientes-agregar-jsx" */),
  "component---src-pages-admin-clientes-index-jsx": () => import("./../../../src/pages/admin/clientes/index.jsx" /* webpackChunkName: "component---src-pages-admin-clientes-index-jsx" */),
  "component---src-pages-admin-perfil-index-jsx": () => import("./../../../src/pages/admin/perfil/index.jsx" /* webpackChunkName: "component---src-pages-admin-perfil-index-jsx" */),
  "component---src-pages-admin-prospectos-[prospecto]-jsx": () => import("./../../../src/pages/admin/prospectos/[prospecto].jsx" /* webpackChunkName: "component---src-pages-admin-prospectos-[prospecto]-jsx" */),
  "component---src-pages-admin-prospectos-agregar-jsx": () => import("./../../../src/pages/admin/prospectos/agregar.jsx" /* webpackChunkName: "component---src-pages-admin-prospectos-agregar-jsx" */),
  "component---src-pages-admin-prospectos-index-jsx": () => import("./../../../src/pages/admin/prospectos/index.jsx" /* webpackChunkName: "component---src-pages-admin-prospectos-index-jsx" */),
  "component---src-pages-afiliacion-facturacion-jsx": () => import("./../../../src/pages/afiliacion/facturacion.jsx" /* webpackChunkName: "component---src-pages-afiliacion-facturacion-jsx" */),
  "component---src-pages-afiliacion-index-jsx": () => import("./../../../src/pages/afiliacion/index.jsx" /* webpackChunkName: "component---src-pages-afiliacion-index-jsx" */),
  "component---src-pages-afiliacion-perfil-jsx": () => import("./../../../src/pages/afiliacion/perfil.jsx" /* webpackChunkName: "component---src-pages-afiliacion-perfil-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */)
}

