export const negociaciones = [
  {
    id: 1,
    label: "Primer Contacto",
		style: "color:link"
  },
  {
    id: 2,
    label: "En progreso",
		style: "color:warning"
  },
  {
    id: 3,
    label: "Firma de contrato",
		style: "color:presuccess"
  },
  {
    id: 4,
    label: "Completado",
		style: "color:success"
  },
  {
    id: 5,
    label: "Cancelado",
		style: "color:danger"
  },
  {
    id: 999,
    label: "En espera",
		style: "color:wait"
  },
];

export const porcentajes = [
  {
    id: 1,
		short: '5%',
    label: "5% de comisión por recomendación",
  },
  {
    id: 2,
		short: '10%',
    label: "10% de comisión por recomendación",
  },
  {
    id: 3,
		short: '15%',
    label: "15% de comisión por recomendación",
  },
  {
    id: 9999,
		short: '',
    label: "Otro porcentaje de comision",
  }
];

export const servicios = [
  {
    id: 1,
    label: "Marketing Digital",
  },
  {
    id: 2,
    label: "Desarrollo Web",
  },
  {
    id: 3,
    label: "Branding Corporativo",
  },
  {
    id: 4,
    label: "Social Media",
  },
  {
    id: 5,
    label: "Posicionamiento SEO",
  },
  {
    id: 6,
    label: "Diseño Gráfico",
  },
  {
    id: 7,
    label: "Coaching Empresarial",
  },
];
