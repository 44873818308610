export const MESES = [
	{
		value: 0,
		label: "Ene",
		large: "Enero",
	},
	{
		value: 1,
		label: "Feb",
		large: "Febrero",
	},
	{
		value: 2,
		label: "Mar",
		large: "Marzo",
	},
	{
		value: 3,
		label: "Abr",
		large: "Abril",
	},
	{
		value: 4,
		label: "May",
		large: "Mayo",
	},
	{
		value: 5,
		label: "Jun",
		large: "Junio",
	},
	{
		value: 6,
		label: "Jul",
		large: "Julio",
	},
	{
		value: 7,
		label: "Ago",
		large: "Agosto",
	},
	{
		value: 8,
		label: "Sep",
		large: "Septiembre",
	},
	{
		value: 9,
		label: "Oct",
		large: "Octubre",
	},
	{
		value: 10,
		label: "Nov",
		large: "Noviembre",
	},
	{
		value: 11,
		label: "Dic",
		large: "Diciembre",
	},
];

export const DIA_SEMANA = {
	0: "Domingo",
	1: "Lunes",
	2: "Martes",
	3: "Miercoles",
	4: "Jueves",
	5: "Viernes",
	6: "Sábado",
};
