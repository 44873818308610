const CONTEXT = 'adminer'
const MSDATE = {
	MS_SECONDS: 1000,
	MS_MINUTES: 1000 * 60,
	MS_HOURS: 1000 * 60 * 60,
	MS_DAYS: 1000 * 60 * 60 * 24
};

export { CONTEXT, MSDATE }

