import add from "@assets/icons/add.svg";
import list from "@assets/icons/list.svg";
import prospectos from "@assets/icons/prospectos.svg";
import clientes from "@assets/icons/clientes.svg";
import comisiones from "@assets/icons/comisiones.svg";
import wallet from "@assets/icons/wallet.svg";
import perfil from "@assets/icons/profile.svg";
import blog from "@assets/icons/blog.svg";
import house from "@assets/icons/home.svg";

export default {
	superadmin: [
		{
			icon: house,
			label: "Ir al inicio",
			link: "/",
			w: 90,
		},
		{
			icon: perfil,
			label: "Mi Perfil",
			link: "/admin/perfil",
			w: 65,
		},
		{
			icon: blog,
			label: "Blogs",
			link: "/admin/blogs",
			w: 60,
		},
		{
			icon: prospectos,
			label: "Prospectos",
			link: "/admin/prospectos",
			w: 85,
		},
		{
			icon: clientes,
			label: "Clientes",
			link: "/admin/clientes",
			w: 80,
		},
	],
	afiliado: [
		{
			icon: house,
			label: "Ir al inicio",
			link: "/",
			w: 75,
		},
		{
			icon: perfil,
			label: "Mi Perfil",
			link: "/afiliacion/perfil",
			w: 65,
		},
		{
			icon: wallet,
			label: "Facturación",
			link: "/afiliacion/facturacion",
			w: 90,
		},
		{
			icon: comisiones,
			label: "Comisiones",
			link: "/afiliacion/",
			w: 90,
		},
	],
	cliente: [
		{
			icon: house,
			label: "Ir al inicio",
			link: "/",
			w: 90,
		},
	],
};
