import React, { useState, useEffect } from "react";
import defaultState, { defaultData } from "./defaultContext";
import { CONTEXT } from "@constants";

const AdminContext = React.createContext(defaultState);

const AdminProvider = ({ children }) => {
  const [auth, setAuth] = useState(defaultState.auth);
  const [notify, setNotify] = useState(defaultState.notify);
  const [catalogos, setCatalogos] = useState(defaultState.catalogos);
  const [blogs, setBlogs] = useState(defaultState.blogs);
  const [zipCodes, setZipCodes] = useState(defaultState.zipCodes);
  const [requestLoading, setRequestLoading] = useState(defaultState.requestLoading);
  const [requestText, setRequestText] = useState(defaultState.requestText);
  const [route, setRoute] = useState(defaultState.route);

  useEffect(() => {
    const data = {
      auth,
      catalogos,
      notify,
      blogs,
			zipCodes,
			requestLoading,
			requestText,
			route
    };
    sessionStorage.setItem(CONTEXT, JSON.stringify(data));
  }, [auth, catalogos, notify, blogs, zipCodes, requestLoading, requestText, route]);

	useEffect(() => {
		if(typeof window !== "undefined") {
			setRoute(window.location.pathname)
		}
	}, [])

  const clearState = () => {
    setAuth(defaultData.auth);
    setNotify(defaultData.notify);
    setCatalogos(defaultData.catalogos);
    setBlogs(defaultData.blogs);
		setZipCodes(defaultData.zipCodes);
		setRequestLoading(defaultData.requestLoading)
		setRequestText(defaultData.requestText)
		setRoute(defaultData.route)

    sessionStorage.removeItem("lastActivity")
    sessionStorage.removeItem("regressiveCount")
  };

	const setRequest = (message) => {
		message && setRequestText(message);
		setRequestLoading(true);
	}

	const endRequest = () => {
		setRequestLoading(false);
		setRequestText(null)
	}

  return (
    <AdminContext.Provider
      value={{
        auth,
        setAuth,
        catalogos,
        setCatalogos,
        blogs,
        setBlogs,
        clearState,
        notify,
        setNotify,
				zipCodes,
				setZipCodes,
				requestLoading,
				setRequestLoading,
				setRequest,
				endRequest,
				route,
				setRoute
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContext;
export { AdminProvider };
